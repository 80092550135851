<template>
  <div>
    <dialog-component
      title="Document Expiration & Reminders"
      :visible.sync="docSettingsVisible"
      v-loading="settingLoading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      top="25px"
      @before-close="close"
      class="doc-modal"
      width="100%"
    >
      <div class="sidemenu">
        <el-form ref="form" :model="settingsData">
          <el-row>
            <el-col :span="24">
              <div class="card-body">
                <div class="py-3 px-2">
                  <h4 class="fs-6 text-dark fw-medium">
                    Document Security & Authentication
                  </h4>

                  <el-checkbox
                    v-model="
                      settings.secure_e_sign.document_access_with_password
                    "
                  >
                    Document Access with 'Password' Authentication
                  </el-checkbox>

                  <div class="py-2">
                    <el-switch
                      v-model="settings.secure_e_sign.allow_document_e_sign"
                      :active-value="true"
                      :inactive-value="false"
                      active-text="Enable E-Signature 'OTP' Authentication"
                    >
                    </el-switch>
                  </div>
                </div>
                <div
                  class="mb-1 ml-2"
                  v-if="settings.secure_e_sign.allow_document_e_sign"
                >
                  <el-checkbox-group
                    v-model="settings.secure_e_sign.notify_through"
                  >
                    <el-checkbox label="Send_Email">Send Email</el-checkbox>
                    <el-checkbox label="Send_Sms">Send Sms</el-checkbox>
                    <!-- <el-checkbox label="Send_Whatsapp">Send Whatsapp</el-checkbox>  -->
                  </el-checkbox-group>
                  <span
                    style="color: red"
                    ref="errorElement"
                    v-if="
                      sendError &&
                      settings.secure_e_sign.notify_through &&
                      settings.secure_e_sign.notify_through.length < 1
                    "
                  >
                    Please Select Atleast One Option
                  </span>
                </div>
              </div>
              <el-divider class="m-0"></el-divider>
              <div class="card-body">
                <div class="pb-3 px-2">
                  <h4 class="fs-6 text-dark fw-medium">Update entity data</h4>
                  <p>
                    If you used entity fields in the document,you can update the
                    entity data with the document data.
                  </p>
                  <div class="d-flex w-100 align-items-center">
                    <el-radio-group v-model="settings.update_entity_data">
                      <el-radio :label="0"
                        >Update after document completion</el-radio
                      >
                      <el-radio :label="1"
                        >Update after user finishes the document</el-radio
                      >
                      <el-radio :label="2">Never update</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
              <el-divider class="m-0"></el-divider>
              <div class="card-body">
                <div class="pb-3 px-2">
                  <h4 class="fs-6 text-dark fw-medium">
                    Document Generation Settings
                  </h4>
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.document_generation_settings
                          .add_document_generated_id
                      "
                    >
                      Add eSigns generated id</el-checkbox
                    >
                    <el-checkbox
                      v-model="
                        settings.document_generation_settings.add_page_number
                      "
                    >
                      Add page numbers</el-checkbox
                    >
                  </div>
                </div>
              </div>
              <el-divider class="m-0"></el-divider>
              <div class="card-body">
                <div class="pb-3 px-2">
                  <h4 class="fs-6 text-dark fw-medium">Send attachment</h4>
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="settings.email_attachement.attach_pdf"
                    ></el-checkbox>
                    <div class="px-2 word-break-normal">
                      {{
                        "Send completed document as attachment in mail after document completed."
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <el-divider class="m-0"></el-divider>
              <div class="card-body">
                <div class="pb-3 px-2">
                  <h4 class="fs-6 text-dark fw-medium">Expiration Settings</h4>
                  <p>
                    Set the expiry date for your document validity so the system
                    does it for you automatically.
                  </p>
                  <div class="d-flex w-100 align-items-center">
                    <el-checkbox
                      v-model="settings.fordate"
                      @change="datechange"
                    ></el-checkbox>
                    <div style="max-width: 200px; margin-left: 10px">
                      {{
                        getIsMobile
                          ? "Expire sent docs in"
                          : "Expire sent documents in"
                      }}
                    </div>
                    <div class="pl-2" style="max-width: 180px">
                      <el-input
                        placeholder="0"
                        min="1"
                        type="number"
                        :disabled="!settings.fordate"
                        v-on:input="changeExpireDate"
                        v-model="
                          settings.expiration_settings.expire_documents_in_days
                        "
                        size="medium"
                      >
                        <template slot="append">
                          <i class="el-icon-date"></i> days</template
                        >
                      </el-input>
                    </div>
                  </div>
                  <div class="expiration-reminder-check mt-1">
                    <div class="d-flex align-items-center">
                      <div class="px-2 word-break-normal">Expire Date</div>
                      <el-date-picker
                        v-model="
                          settings.expiration_settings.document_expired_date
                        "
                        disabled
                      ></el-date-picker>
                    </div>
                  </div>
                  <div class="expiration-reminder-check mt-1">
                    <div class="d-flex align-items-center">
                      <el-checkbox
                        v-model="
                          settings.expiration_settings
                            .send_expiration_reminder_status
                        "
                      ></el-checkbox>
                      <div class="px-2 word-break-normal">
                        {{ getIsMobile ? "Remind in" : "Send First Reminder" }}
                      </div>
                      <el-input-number
                        v-model="
                          settings.expiration_settings
                            .send_first_reminder_in_days
                        "
                        :disabled="
                          !settings.expiration_settings
                            .send_expiration_reminder_status
                        "
                        :min="1"
                        :max="
                          settings.expiration_settings.expire_documents_in_days
                        "
                      ></el-input-number>
                      <div class="pl-1 word-break-normal">
                        Before Expiration
                      </div>
                    </div>
                  </div>
                  <div class="expiration-reminder-check mt-1">
                    <div class="d-flex align-items-center">
                      <el-checkbox
                        v-model="
                          settings.expiration_settings
                            .repeat_expiration_reminder_status
                        "
                      ></el-checkbox>
                      <div class="px-2 word-break-normal">
                        {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                      </div>
                      <el-input-number
                        v-model="
                          settings.expiration_settings
                            .repeat_expiration_reminder
                        "
                        :disabled="
                          !settings.expiration_settings
                            .repeat_expiration_reminder_status
                        "
                        :min="1"
                        :max="
                          settings.expiration_settings.expire_documents_in_days
                        "
                      ></el-input-number>
                      <div class="pl-1">in Days</div>
                    </div>
                  </div>
                </div>
              </div>
              <el-divider class="m-0"></el-divider>
              <div class="card-body">
                <div class="py-3 px-2">
                  <h4 class="fs-6 text-dark fw-medium">
                    Auto Reminders Settings
                  </h4>
                  <p>
                    Automatically send email reminders to signers regarding the
                    incompletion of document.
                  </p>
                  <div class="d-flex w-100 align-items-center mb-1">
                    <el-checkbox
                      v-model="
                        settings.auto_remainders_settings.first_reminder_status
                      "
                    ></el-checkbox>
                    <div
                      class="px-2 word-break-normal"
                      style="max-width: 200px"
                    >
                      {{ getIsMobile ? "Remind in" : "Send First Reminder" }}
                    </div>
                    <div class="pl-2" style="max-width: 180px">
                      <el-input
                        placeholder="0"
                        type="number"
                        min="0"
                        :max="
                          settings.expiration_settings.expire_documents_in_days
                        "
                        :disabled="
                          !settings.auto_remainders_settings
                            .first_reminder_status
                        "
                        v-model="
                          settings.auto_remainders_settings
                            .send_first_reminder_in_days
                        "
                        size="medium"
                      >
                        <template slot="append">
                          <i class="el-icon-date"></i> days</template
                        >
                      </el-input>
                    </div>
                  </div>

                  <div class="expiration-reminder-check">
                    <div class="d-flex align-items-center">
                      <el-checkbox
                        v-model="
                          settings.auto_remainders_settings
                            .repeat_reminder_status
                        "
                      ></el-checkbox>
                      <div class="px-2 word-break-normal">
                        {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                      </div>
                      <el-input-number
                        v-model="
                          settings.auto_remainders_settings.repeat_reminder
                        "
                        :disabled="
                          !settings.auto_remainders_settings
                            .repeat_reminder_status
                        "
                        :min="1"
                        :max="
                          settings.expiration_settings.expire_documents_in_days
                        "
                      ></el-input-number>
                      <div class="pl-1 word-break-normal">
                        {{
                          getIsMobile ? "Days" : "Until completed &amp; expired"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-divider class="m-0"></el-divider>
          <el-form-item class="mb-0 pt-2 px-2 text-right"> </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="close" plain>Cancel</el-button>
        <el-button
          type="primary"
          :loading="settingLoading"
          @click="updateDocSettingsData"
          >Save</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  name: "configureDocuments-ConfigureSettingsNewTemp",
  props: ["configurableDocumentId", "docSettingsVisible", "settingsData"],

  computed: {
    ...mapGetters("settings", ["getUserSettings"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("documents", [
      "getDocumentSettings",
      "getDocumentSettingsUpdateStatus",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },

  data() {
    return {
      settingLoading: false,
      loadingText: "",
      sendError: false,
      settings: {
        fordate: false,
        secure_e_sign: {
          document_access_with_password: false,
          allow_document_e_sign: false,
          notify_through: [],
        },
        auto_remainders_settings: {
          send_first_reminder_in_days: "",
          repeat_reminder: "",
          repeat_reminder_status: true,
          first_reminder_status: true,
        },
        expiration_settings: {
          send_expiration_reminder_status: true,
          repeat_expiration_reminder_status: true,
          send_first_reminder_in_days: "",
          is_default: true,
          repeat_expiration_reminder: "",
          expire_documents_in_days: "",
          document_expired_date: "",
        },
        update_entity_data: 0,
        document_generation_settings: {
          add_document_generated_id: true,
          add_page_number: true,
        },
        email_attachement: {
          attach_pdf: true,
        },
      },
      expirationRemainder: true,
      sendFirstRemainder: true,
      repeatRemainderCheck: true,
    };
  },
  async mounted() {
    await this.fetchDocumentSettings({
      document_id:
        this.$route.params.employee_document_id ||
        this.$route.params.id ||
        this.configurableDocumentId,
    });

    if (this.getDocumentSettings) {
      let data = this.getDocumentSettings;
      this.settings.expiration_settings.send_expiration_reminder_status =
        data.data.expiration_settings.send_expiration_reminder_status;
      this.settings.expiration_settings.repeat_expiration_reminder_status =
        data.data.expiration_settings.repeat_expiration_reminder_status;
      this.settings.expiration_settings.expire_documents_in_days =
        data.data.expiration_settings.expire_documents_in_days;
      this.settings.expiration_settings.send_first_reminder_in_days =
        data.data.expiration_settings.send_first_reminder_in_days;
      this.settings.expiration_settings.repeat_expiration_reminder =
        data.data.expiration_settings.repeat_expiration_reminder;
      this.settings.auto_remainders_settings.repeat_reminder =
        data.data.auto_remainders_settings.repeat_reminder;
      this.settings.auto_remainders_settings.send_first_reminder_in_days =
        data.data.auto_remainders_settings.send_first_reminder_in_days;
      this.settings.auto_remainders_settings.repeat_reminder_status =
        data.data.auto_remainders_settings.repeat_reminder_status;
      this.settings.auto_remainders_settings.first_reminder_status =
        data.data.auto_remainders_settings.first_reminder_status;
      this.settings.secure_e_sign.document_access_with_password =
        data.secure_e_sign &&
        data.secure_e_sign.document_access_with_password &&
        data.secure_e_sign.document_access_with_password === true
          ? true
          : false;
      this.settings.secure_e_sign.allow_document_e_sign =
        data.data.secure_e_sign &&
        (data.data.secure_e_sign.allow_document_e_sign == false ||
          data.data.secure_e_sign.allow_document_e_sign == true)
          ? data.data.secure_e_sign.allow_document_e_sign
          : true;
      this.settings.update_entity_data =
        data.data && data.data.update_entity_data
          ? data.data.update_entity_data
          : 0;
      this.settings.document_generation_settings.add_document_generated_id =
        data.data.document_generation_settings.add_document_generated_id;
      this.settings.document_generation_settings.add_page_number =
        data.data.document_generation_settings.add_page_number;
      this.settings.email_attachement.attach_pdf =
        data.data &&
        data.data.email_attachement &&
        data.data.email_attachement.attach_pdf
          ? data.data.email_attachement.attach_pdf
          : false;
      this.settings.expiration_settings.document_expired_date =
        data.data.expiration_settings.document_expired_date;
      this.settings.secure_e_sign.notify_through = data?.data?.secure_e_sign
        ?.notify_through || ["Send_Email"];
      this.changeExpireDate();
    } else {
      this.fetchUserSettings();
      this.changeExpireDate();
    }
  },

  methods: {
    ...mapActions({
      fetchDocumentSettings: "documents/fetchDocumentSettings",
    }),

    close() {
      this.$emit("close");
      console.log("calling close");
    },
    scrollToError() {
      if (this.$refs.errorElement) {
        this.$refs.errorElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    async fetchEmployeDocumentData() {
      await this.$store.dispatch("documents/fetchDocumentDataById", {
        document_id: this.$route.params.employee_document_id,
      });
    },

    datechange() {
      if (this.settings.fordate === true) {
        this.changeExpireDate();
      } else if (this.settings.fordate === false) {
        this.settings.expiration_settings.document_expired_date =
          this.getDocumentSettings.data.expiration_settings.document_expired_date;
      }
    },
    async fetchUserSettings() {
      try {
        this.loading = true;
        await this.$store.dispatch("settings/fetchUserDocumentSettings");
        this.setUserSettings();

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    setUserSettings() {
      let data = this.getUserSettings;
      this.settings.expiration_settings.send_expiration_reminder_status =
        data.expiration_settings.send_expiration_reminder_status;
      this.settings.expiration_settings.repeat_expiration_reminder_status =
        data.expiration_settings.repeat_expiration_reminder_status;
      this.settings.expiration_settings.expire_documents_in_days =
        data.expiration_settings.expire_documents_in_days;
      this.settings.expiration_settings.send_first_reminder_in_days =
        data.expiration_settings.send_first_reminder_in_days;
      this.settings.expiration_settings.repeat_expiration_reminder =
        data.expiration_settings.repeat_expiration_reminder;
      this.settings.auto_remainders_settings.repeat_reminder =
        data.auto_remainders_settings.repeat_reminder;
      this.settings.auto_remainders_settings.send_first_reminder_in_days =
        data.auto_remainders_settings.send_first_reminder_in_days;
      this.settings.auto_remainders_settings.repeat_reminder_status =
        data.auto_remainders_settings.repeat_reminder_status;
      this.settings.auto_remainders_settings.first_reminder_status =
        data.auto_remainders_settings.first_reminder_status;
      this.settings.secure_e_sign.document_access_with_password =
        data.secure_e_sign &&
        data.secure_e_sign.document_access_with_password &&
        data.secure_e_sign.document_access_with_password === true
          ? true
          : false;
      this.settings.secure_e_sign.allow_document_e_sign =
        data.secure_e_sign &&
        (data.secure_e_sign.allow_document_e_sign == false ||
          data.secure_e_sign.allow_document_e_sign == true)
          ? data.secure_e_sign.allow_document_e_sign
          : true;

      this.settings.secure_e_sign.notify_through =
        data.secure_e_sign.notify_through;
      this.changeExpireDate();
    },

    async updateDocSettingsData() {
      if (
        this.settings.secure_e_sign &&
        this.settings.secure_e_sign.allow_document_e_sign === true &&
        this.settings.secure_e_sign.notify_through.length < 1
      ) {
        this.sendError = true;
        this.$nextTick(() => {
          this.scrollToError();
        });
      } else {
        this.settingLoading = true;
        this.loadingText = "Updating settings...";
        // if (this.getDocumentSettings) {
        //   if (
        //     this.settings.secure_e_sign.allow_document_e_sign ===
        //     this.getDocumentSettings.data.secure_e_sign.allow_document_e_sign
        //   ) {
        //     this.securechange = "null";
        //   } else {
        //     this.securechange = this.settings.secure_e_sign.allow_document_e_sign;
        //   }
        // } else {
        //   if (this.settings.secure_e_sign.allow_document_e_sign === true) {
        //     this.securechange = "null";
        //   } else {
        //     this.securechange = this.settings.secure_e_sign.allow_document_e_sign;
        //   }
        // }

        // if (this.getDocumentSettings) {
        //   if (
        //     this.settings.expiration_settings.document_expired_date ===
        //     this.getDocumentSettings.data.expiration_settings
        //       .document_expired_date
        //   ) {
        //     this.oldexpirationdate = "null";
        //   } else {
        //     if (!this.getDocumentSettings) {
        //       this.oldexpirationdate =
        //         this.settings.expiration_settings.document_expired_date;
        //     } else {
        //       this.oldexpirationdate =
        //         this.getDocumentSettings.data.expiration_settings.document_expired_date;
        //     }
        //   }
        // } else {
        //   if (
        //     this.settings.expiration_settings.document_expired_date ===
        //     this.getUserSettings.expiration_settings.document_expired_date
        //   ) {
        //     this.oldexpirationdate = "null";
        //   } else {
        //     if (!this.getDocumentSettings) {
        //       this.oldexpirationdate =
        //         this.settings.expiration_settings.document_expired_date;
        //     } else {
        //       this.oldexpirationdate =
        //         this.getDocumentSettings.data.expiration_settings.document_expired_date;
        //     }
        //   }
        // }

        let data = {
          // securechange: this.securechange,
          // oldexpirationdate: this.oldexpirationdate,
          // employee_document_id: this.$route.params.employee_document_id,
          // configurable_document_id: this.configurableDocumentId,
          // popup: "true",
          document_settings: this.settings,
        };
        let document_id =
          this.$route.params.employee_document_id ||
          this.$route.params.id ||
          this.configurableDocumentId;
        await this.$store.dispatch("documents/updateDocumentSettings", {
          document_id,
          data,
        });
        if (this.getDocumentSettingsUpdateStatus) {
          this.settingLoading = false;
          this.$notify.success({
            title: "Success",
            message: "Settings updated successfully",
          });
          // this.fetchEmployeDocumentData();
          this.$emit("close");
          this.$emit("updatedSettings");
          this.$emit("updated");
        }
      }
    },

    changeExpireDate() {
      console.log(this.settings.expiration_settings.expire_documents_in_days);
      this.settings.expiration_settings.document_expired_date =
        this.$moment().add(
          this.settings.expiration_settings.expire_documents_in_days,
          "days"
        );
    },
  },
  beforeDestroy() {
    this.$store.commit("documents/setDocumentSettingsUpdateStatus", null, {
      root: true,
    });
    this.$store.commit("settings/setUserSettings", null, { root: true });
    this.$store.commit("documents/setDocumentSettings", null, {
      root: true,
    });
  },
};
</script>
<style lang="scss" scoped>
.sidemenu {
  height: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.expiration-reminder-check {
  margin-bottom: 15px;
}
.el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #fff;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
</style>
